/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    strong: "strong",
    h2: "h2",
    h3: "h3",
    ol: "ol",
    a: "a",
    h4: "h4",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components), {Admonition, FilteredContent, RequestParameters, RequestSample, ResponseBody, ResponseSample} = _components;
  if (!Admonition) _missingMdxReference("Admonition", true);
  if (!FilteredContent) _missingMdxReference("FilteredContent", true);
  if (!RequestParameters) _missingMdxReference("RequestParameters", true);
  if (!RequestSample) _missingMdxReference("RequestSample", true);
  if (!ResponseBody) _missingMdxReference("ResponseBody", true);
  if (!ResponseSample) _missingMdxReference("ResponseSample", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "A pricing intent is a template of fees that you apply to processing accounts when you board merchants. You can apply the same pricing intent to all processing accounts that you create or create multiple pricing intents to apply to different processing accounts. A pricing intent can include the following fee types:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Base fees"), ": For monthly maintenance, chargebacks, and early contract termination. All pricing intents must include base fees."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Processor fees"), ": For card payments and Automated Clearing House (ACH) payments. Processor fees also include the pricing model that you offer to the merchant, for example, RewardPay."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Gateway fees"), ": For using our payment gateway."), "\n"), "\n", React.createElement(_components.h2, null, "Integration steps"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Create a pricing intent."), "\n"), "\n", React.createElement(_components.h2, null, "Before you begin"), "\n", React.createElement(_components.h3, null, "Bearer tokens"), "\n", React.createElement(_components.p, null, "Use our Identity Service to generate a Bearer token to include in the header of your requests. To generate your Bearer token, complete the following steps:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Include your API key in the x-api-key parameter in the header of a POST request."), "\n", React.createElement(_components.li, null, "Send your request to ", React.createElement(_components.a, {
    href: "https://identity.payroc.com/authorize"
  }, "https://identity.payroc.com/authorize"), "."), "\n"), "\n", React.createElement(Admonition, null, React.createElement(_components.p, null, "You need to generate a new Bearer token before the previous Bearer token expires.")), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.h4, null, "Example request"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "curl --location --request POST  'https://identity.payroc.com/authorize' --header 'x-api-key: <api key>'\n"))), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(_components.h4, null, "Example response"), React.createElement(_components.p, null, "If your request is successful, we return a response that contains your Bearer token, information about its scope, and when it expires."), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n\"access_token\": \"eyJhbGc....adQssw5c\",\n\"expires_in\": 3600,\n\"scope\": \"service_a service_b\",\n\"token_type\": \"Bearer\"\n}\n"))), "\n", React.createElement(_components.h3, null, "Headers"), "\n", React.createElement(_components.p, null, "To create the header of each POST request, you must include the following parameters:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Content-Type:"), " Include application/json as the value for this parameter."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Authorization:"), " Include your Bearer token in this parameter."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Idempotency-Key:"), " Include a UUID v4 to make the request idempotent."), "\n"), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "curl\n-H \"Content-Type: application/json\"\n-H \"Authorization: <Bearer token>\"\n-H \"Idempotency-Key: <UUID v4>\"\n"))), "\n", React.createElement(_components.h3, null, "Errors"), "\n", React.createElement(_components.p, null, "If your request is unsuccessful, we return an error. For more information about errors, see ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/errors"
  }, "Errors"), "."), "\n", React.createElement(_components.h2, null, "Create a pricing intent"), "\n", React.createElement(_components.p, null, "Send a POST request to our Pricing Intents endpoint:"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Test endpoint"), ": ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/pricing-intents"
  }, "https://api.uat.payroc.com/v1/pricing-intents")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Production endpoint"), ": ", React.createElement(_components.a, {
    href: "https://api.payroc.com/v1/pricing-intents"
  }, "https://api.payroc.com/v1/pricing-intents")), "\n", React.createElement(_components.h3, null, "Request parameters"), "\n", React.createElement(FilteredContent, {
    filter: "Request parameters"
  }, React.createElement(RequestParameters, {
    operationId: "createPricingIntent"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.h3, null, "Example request"), React.createElement(RequestSample, {
    operationId: "createPricingIntent"
  })), "\n", React.createElement(_components.h3, null, "Response fields"), "\n", React.createElement(_components.p, null, "If your request is successful, our gateway creates the pricing intent. The response contains the following fields:"), "\n", React.createElement(FilteredContent, {
    filter: "Response body"
  }, React.createElement(ResponseBody, {
    operationId: "createPricingIntent"
  })), "\n", React.createElement(_components.h3, null, "Example response"), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(ResponseSample, {
    operationId: "createPricingIntent"
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
